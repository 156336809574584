@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Neucha|Raleway:400,800');


$primary: #3584b9;
/* MAIN COLOR */
$secondary: #fc5d5f;
/* SECONDARY COLOR */
$blk: #000;
$wht: #fff;

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
}

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

html,
body {
    height: 100%;
  font-family: 'Raleway',
  sans-serif;

}

html {
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-size: .8rem;
}

h1 {
    font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}

h2 {
    font-size: $mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}

h3 {
    font-size: $mod_1*$mod_1 *1rem;
    @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}


q,
blockquote {
    quotes: "“""”";
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.logo {
    max-height: 100px;

    @media(max-width: 767px) {
        max-height: 60px;
    }
}

nav {
    width: 100%;
    z-index: 2;
    top: 0;
    left: 0;
}

nav.navbar {
    z-index: 1000;
    border: none;

    .navbar-nav {
        >li>a.nav-link {
            text-align: center;
            color: $blk;
            margin-top: 0;
            font-size: .8rem;
            @include border-radius(3px);

            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }

            &:hover {
                background: $primary;
                color: $wht;

                @media (max-width: 1024px) {
                    background: transparent;
                    color: $wht;
                    outline: 0;
                }
            }
        }
    }
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

// .navbar-right {
//     margin-top: 0px;
// }

/** LOGIN FORM **/

@mixin btn {
    background: $primary;
    color: $wht;
    padding: 1em 2em;
    font-size: .9rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    transition: 0.7s ease all;
    border: none;
    margin-bottom: 1em;
    @include border-radius(5px);
    

    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;

        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;

    @media(max-width: 823px)and (orientation:landscape) {
        margin: 0 auto;
    }

    .close {
        display: none;
    }

    .modal-content {
        color: $primary;

        h2 {
            text-align: center;
        }
    }

    input {
        border: 1px solid #777;
        text-align: center;
    }

    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }

    button {
        background: transparent;
        margin-top: 10px;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;

        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }

    input[type="submit"] {
        @include btn;
        display: block;
        width: 100%;
        font-size: 18px;
        margin: 10px auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;

    .modal {
        padding-right: 0 !important;
    }
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

// start section here 
section {

    h1,
    h2,
    h3 {
     font-family: 'Neucha',
     cursive;



    }
}

span {
    font-weight: bold
}

.pd {
    padding: 150px 0;

    @media(max-width: 1024px) {
        padding: 100px 0;
    }

    @media(max-width:767px) {
        padding: 50px 0;
    }
}

img {
    @include border-radius(5px);
}

.section-a,
.section-b,
.section-c {
    @extend .pd;
}

.section {
    background-image: linear-gradient(-90deg, rgba($primary, .5), rgba($primary, .7)), url("../img/bg1.jpg");
    background-position:s center;
    background-size: cover;
    padding: 100px 0;
    color: $wht;
    @media(max-width:400px) {
        padding: 50px 0;
    }
}

.section-a,
.section-c {
    background: lighten($primary, 25%);
}
.section-a {
    background-color: lighten($blk,95%);
    .col-md-9 {
        margin-top: 5%;
    }
}
.section-b {
    background-color: darken($wht, 10%);
}
.section-c {
    color: $blk;
}
// end section 


footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $wht;
    font-size: .7rem;

    a {
        color: $wht;
        white-space: nowrap;
        font-size: .7rem;


        &:hover,
        &:focus {
            color: $wht;
        }
    }

    p {
        font-size: .7rem;

    }
}

input#username,
input#password {
    width: 100%;

    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

.top-pad {
    padding: 3em 0;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }
}

.terms,
.privacy {
    font-size: 1rem;

    & ol {
        li {
            font-size: 1rem;
        }

        & ol li {
            font-size: 0.85rem;
        }
    }
}

.modal-dialog {
    @media (min-width: 576px) {
        max-width: 350px;
    }

    input {
        width: 100%;
    }
}



/*
 * Dummy devices (replace them with your own or something else entirely!)
 */

.product-device {
    position: absolute;
    right: 10%;
    bottom: -30%;
    width: 300px;
    height: 540px;
    background-color: #333;
    border-radius: 21px;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);

}

.product-device::before {
    position: absolute;
    top: 10%;
    right: 10px;
    bottom: 10%;
    left: 10px;
    content: "";
    background-color: rgba(255, 255, 255, .1);

    border-radius: 5px;
}

.product-device-2 {
    top: -25%;
    right: auto;
    bottom: 0;
    left: 5%;
    background-color: #e5e5e5;

}


/*
 * Extra utilities
 */

.border-top {
    border-top: 1px solid #e5e5e5;
}

.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.flex-equal>* {
    -ms-flex: 1;
    -webkit-box-flex: 1;
    flex: 1;
}

@media (min-width: 768px) {
    .flex-md-equal>* {
        -ms-flex: 1;
        -webkit-box-flex: 1;
        flex: 1;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.img-holder1 {
    background-image: url("../img/img7.jpg");
    background-size: cover;
    background-position: top center;
}

.img-holder2 {
    background-image: url("../img/img8.jpg");
    background-size: cover;
    background-position: top center;
}